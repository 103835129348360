<template>
  <div class="div">
    <div class="minwidth">
      <div class="width30"></div>
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="page 1"></el-step>
        <el-step title="page 2"></el-step>
        <el-step title="page 3"></el-step>
        <el-step title="page 4"></el-step>
      </el-steps>
      <div class="width20"></div>

      <div class="div" v-show="active == 1">
        <div class="html" v-html="Content.first" v-if="Content.first"></div>
        <!-- :rules="rules" -->
        <el-form :model="form" label-width="80px" class="demo-ruleForm" ref="form">
          <el-form-item label="Host's Name" prop="xing">
            <div class="flex-sb">
              <el-input placeholder="First" v-model="form.xing" style="width: 48%;margin-right: 20px;"></el-input>
              <el-input placeholder="Last" v-model="form.ming" style="width: 48%;"></el-input>
            </div>
          </el-form-item>

          <div class="flex-sb">
            <el-form-item label="Host Email" style="width: 48%;" prop="email">
              <el-input placeholder="First" v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="phone" style="width: 48%;" prop="phone">
              <el-input placeholder="Last" v-model="form.phone"></el-input>
            </el-form-item>
          </div>

          <el-form-item label="Home Address" style="width: 100%;" prop="email">
            <el-input placeholder="Address Line 1" v-model="form.home_address"></el-input>
            <div class="width10"></div>
            <el-input placeholder="Address Line 2" v-model="form.home_address_two"></el-input>
          </el-form-item>

          <div class="flex-sb" style="width:100%">
            <el-form-item label="" prop="city" style="width: 40%;">
              <el-input v-model="form.city" placeholder="City" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="" prop="State">
              <el-select v-model="form.State" filterable>
                <el-option v-for="(item, index) in SelectList" :key="index" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="postal">
              <el-input v-model="form.postal" placeholder="Postal / Zip Code"></el-input>
            </el-form-item>
          </div>

          <div class="flex-sb" style="width:100%">
            <el-form-item label="Date">
              <el-date-picker v-model="form.rl" type="date" placeholder="(mm/dd/yyyy)" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="Date">
              <el-date-picker v-model="form.rl" type="date" placeholder="(mm/dd/yyyy)" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item> -->
            <el-form-item label="Signature">
              <vue-esign ref="esign" class="mySign" :isCrop="isCrop" :lineColor="lineColor" :bgColor.sync="bgColor" />
              <el-button @click="handleReset" class="handleReset">reset</el-button>
            </el-form-item>
          </div>

          <div class="admin">Administration Only - Do Not Change</div>
          <el-form-item label="Student Name">
            <div class="flex-sb">
              <el-input placeholder="First" v-model="form.First" style="width: 48%;margin-right: 20px;"></el-input>
              <el-input placeholder="Last" v-model="form.Last" style="width: 48%;"></el-input>
            </div>

          </el-form-item>
          <div class="flex-sb">
            <el-form-item label="Fiscal Date (Move-in Date)" style="width: 100%;">
              <el-input placeholder="(mm/dd/yyyy)" v-model="form.Date" style="width: 92%;"></el-input>
            </el-form-item>
            <el-form-item label="Monthly Homestay Fee" style="width: 100%;">
              <el-input placeholder="" v-model="form.Monthly" style="width: 98%;"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="div" v-show="active == 2">
        <div class="html" v-html="Content.second" v-if="Content.second"></div>
        <el-form class="demo-ruleForm" :model="form" label-width="80px">
          <div class="text">“I hereby agree to the terms and conditions set forth above.”</div>
          <div class="hostname">Host Name: {{ form.xing }}{{ form.ming }}</div>
          <div class="flex-sb">
            <el-form-item label="Date">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.calendar1" type="date" placeholder="(mm/dd/yyyy)">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Host Signature">
              <vue-esign ref="esign1" class="mySign" :isCrop="isCrop" :lineColor="lineColor" :bgColor.sync="bgColor" />
              <el-button @click="handleReset1" class="handleReset">reset</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="div" v-show="active == 3">
        <div class="html" v-html="Content.third" v-if="Content.third"></div>
        <el-form class="demo-ruleForm" :model="form" label-width="80px">
          <div class="flex-sb">
            <el-form-item label="Date">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.calendar2" type="date" placeholder="(mm/dd/yyyy)">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Host Signature">
              <vue-esign ref="esign2" class="mySign" :isCrop="isCrop" :lineColor="lineColor" :bgColor.sync="bgColor" />
              <el-button @click="handleReset2()" class="handleReset">reset</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="div" v-show="active == 4">
        <div class="html" v-html="Content.fourth" v-if="Content.fourth"></div>
        <el-form class="demo-ruleForm" :model="form" label-width="80px">
          <div class="text">“I hereby acknowledge that I have read and understood the above information. I will, to the
            best of my ability, follow procedures that have been outlined above.”</div>
          <div class="hostname">Host Name:{{ form.xing }} {{ form.ming }}</div>
          <div class="flex-sb">
            <el-form-item label="Date">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.calendar3" type="date" placeholder="(mm/dd/yyyy)">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Host Signature">
              <vue-esign ref="esign3" class="mySign" :isCrop="isCrop" :lineColor="lineColor" :bgColor.sync="bgColor" />
              <el-button @click="handleReset3" class="handleReset">reset</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="flex-sb">
        <div class="mater">
          <el-button @click="back()" v-if="active !== 1">Back</el-button>
          <el-button @click="next(active)" type="primary" v-if="active !== 4">Next</el-button>
          <el-button @click="subimit()" type="primary" v-if="active == 4">Submit</el-button>
        </div>
        <el-button @click="SaveLink()" type="warning" v-if="!this.$route.query.type">Save</el-button>
      </div>

      <div class="width40"></div>
    </div>
    <el-dialog title="Your progress has been saved." :visible.sync="dialogFormVisible">
      <div class="text">Copy or email the link below and return to your form to complete your submission.</div>
      <el-form :model="form">
        <el-form-item label="Copy your form link:">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>

        <el-button type="primary" @click="CopyLink()">复制链接</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { Operator, District, HomeSubmit, Sharelink, DataHX } from '@/api/home.js';
import $ from 'jquery';
export default {
  name: "Home",
  data() {
    return {
      Overall_Data: window.localStorage.getItem('Username'),
      dialogFormVisible: false,
      rules: [],
      // lineWidth: 2, // 画笔的线条粗细
      lineColor: "#000000", // 画笔的颜色
      bgColor: "", // 画布的背景颜色
      resultImg: "", // 最终画布生成的base64图片
      resultImg1: "", // 最终画布生成的base64图片
      resultImg2: "", // 最终画布生成的base64图片
      resultImg3: "", // 最终画布生成的base64图片
      isCrop: false, // 是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      Sign: {},//签名数据
      SelectList: [],
      active: 1,
      form: {
        birth: "",
        xing: "",
        ming: "",
        phone: "",
        city: "",
        State: "",
        postal: "",
        email: "",
        name: "",
        First: "",
        Last: "",
        Date: "",
        Monthly: "",
        rl: "",
        home_address: "",
        home_address_two: "",
        calendar: "", // 第二页日历
        calendar2: "", //第三页日历
        calendar3: "", //第三页日历
      },
      Content: "",
    }
  },
  mounted() {
    // this.HandleHome().then(res => {
    this.HandleData();
    // });
    this.HandleList();

  },

  methods: {
    CopyLink() {

      const input = document.createElement("textarea"); // 创建一个隐藏的 textarea
      input.value = this.form.name;
      document.body.appendChild(input);
      input.select(); // 选中文本
      input.setSelectionRange(0, input.value.length); // 兼容 iOS
      const result = document.execCommand("copy"); // 执行复制
      document.body.removeChild(input); // 移除临时元素
      this.$message.success('复制成功');
    },

    HandleData() {
      var id = this.$route.query.id;
      if (id) {
        DataHX({ id: id }).then(res => {
          if (res.code == 200) {
            this.Content = res.data.live_content_info;
            this.form.First = res.data.student_first_name;
            this.form.Last = res.data.student_last_name;
            this.form.Date = res.data.fiscal_date;
            this.form.Monthly = res.data.monthly_homestay_fee;

            requestAnimationFrame(function () {
              window.localStorage.setItem("Username", `${res.data.student_first_name}${res.data.student_last_name}`);
              document.getElementsByName('deposit_fee')[0].value = res.data.deposit_fee;
              document.getElementsByName('other_choice')[0].value = res.data.other_choice;
              document.getElementsByName('designated_airport')[0].value = res.data.designated_airport;
              $(`input:radio[name=type_of_hosting][value=${res.data.type_of_hosting_id}]`).attr("checked", "true");
              $(`input:radio[name=school_rides][value='${res.data.school_rides}']`).attr("checked", "true");
            });
          }
        });

        return;
      }

      this.HandleHome()
    },
    // save 需要上传首页所有的数据字段
    SaveLink() {
      // 原生js 获取
      var other = document.getElementsByName('other_choice')[0].value;
      var Deposit = document.getElementsByName('deposit_fee')[0].value;
      var airport = document.getElementsByName('designated_airport')[0].value;
      // var school = document.querySelector('input[name="school_rides"]:checked').value;
      // console.log(document.querySelector('input[name="school_rides"]:checked'));
      if (!document.querySelector('input[name="school_rides"]:checked')) { var school = ""; } else { var school = document.querySelector('input[name="school_rides"]:checked').value; }
      if (!document.querySelector('input[name="type_of_hosting"]:checked')) { var hosting = ""; } else { var hosting = document.querySelector('input[name="type_of_hosting"]:checked').value; }
      // var hosting = document.querySelector('input[name="type_of_hosting"]:checked').value;
     
      // if (document.querySelector('input[name="school_rides"]:checked').value !== "Other Choice") { var other = ""; }
  
      let { First, Last, Date, Monthly } = this.form;
      Sharelink({
        student_first_name: First, student_last_name: Last, fiscal_date: Date,
        monthly_homestay_fee: Monthly, school_rides: school, designated_airport: airport, other_choice: other, deposit_fee: Deposit, type_of_hosting: hosting
      }).then(res => {
        if (res.code == 200) {
          this.form.name = `${window.location.href}?${res.data.url}`;
          this.dialogFormVisible = !this.dialogFormVisible;
        }
      });
    },
    handleClose(done) {
      done();
    },
    handleGenerate() {
      this.$refs.esign.generate().then(res => {
        this.resultImg = res;
      }).catch(err => {
        this.$message.error(err);// 画布没有签字时会执行这里 'Not Signned'
        this.active = 1;  //如果没有签名默认不进入第二页
      })
    },
    handleGenerate1() {
      this.$refs.esign1.generate().then(res => {
        this.resultImg1 = res;
      }).catch(err => {
        this.$message.error(err);// 画布没有签字时会执行这里 'Not Signned'
        this.active = 2;  //如果没有签名默认不进入第三页
      })
    },
    handleGenerate2() {
      this.$refs.esign2.generate().then(res => {
        this.resultImg2 = res;
      }).catch(err => {
        this.$message.error(err);// 画布没有签字时会执行这里 'Not Signned'
        this.active = 3;  //如果没有签名默认不进入第四页
      })
    },
    //清空
    handleReset() {
      if (this.$refs.esign) {
        this.$refs.esign.reset();
      }
    },
    handleReset1() {
      if (this.$refs.esign1) {
        this.$refs.esign1.reset();
      }
    },
    handleReset2() {
      if (this.$refs.esign2) {
        this.$refs.esign2.reset();
      }
    },
    handleReset3() {
      if (this.$refs.esign3) {
        this.$refs.esign3.reset();
      }
    },
    next(num) {
      // num 代表当前操作步骤
      if (num == 1) {
        var other = document.getElementsByName('other_choice')[0].value;
        var Deposit = document.getElementsByName('deposit_fee')[0].value;
        var airport = document.getElementsByName('designated_airport')[0].value;
        var school = document.querySelector('input[name="school_rides"]:checked');
        var hosting = document.querySelector('input[name="type_of_hosting"]:checked');
        if (school == "Other Choice" && !other) { this.$message.error('School Rides Other Choice is required'); return }
        if (school !== "Other Choice") { document.getElementsByName('other_choice')[0].value = ""; }

        if (!Deposit || !airport || !school || !hosting) {
          this.$message.error('The message cannot be empty');
          return;
        }
        var { xing, ming, phone, email, city, State, rl, postal, home_address } = this.form;
        if (!xing || !ming) {
          this.$message.error('Hosts Name is required');
          return;
        }
        if (!phone) {
          this.$message.error('phone is required');
          return;
        }
        if (!email) {
          this.$message.error('email is required');
          return;
        }
        if (!home_address) {
          this.$message.error('home_address is required');
          return;
        }
        if (!city) {
          this.$message.error('city is required');
          return;
        }
        if (!State) {
          this.$message.error('State is required');
          return;
        }
        if (!rl) {
          this.$message.error('Date is required');
          return;
        }
        if (!postal) {
          this.$message.error('zip code is required');
          return;
        }
        this.handleGenerate();
      }
      if (num == 2) {
        var { calendar1 } = this.form;
        if (!calendar1) {
          this.$message.error('Date is required');
          return;
        }

        this.handleGenerate1();
        document.getElementById('name_stu').innerHTML = this.Overall_Data;
      }

      if (num == 3) {
        var { calendar2 } = this.form;
        if (!calendar2) {
          this.$message.error('Date is required');
          return;
        }
        this.handleGenerate2();
      }

      // if (num == 4) {

      // }

      if (this.active++ > 4) this.active = 0;
    },
    back() {
      this.active--
    },

    // 获取美国城市列表
    HandleList() {
      District({ type: "us" }).then(res => {
        if (res.code == 200) {
          this.SelectList = res.data
        }
      });
    },

    subimit() {
      var Deposit = document.getElementsByName('deposit_fee')[0].value;
      var airport = document.getElementsByName('designated_airport')[0].value;
      var school = document.querySelector('input[name="school_rides"]:checked').value;
      var hosting = document.querySelector('input[name="type_of_hosting"]:checked').value;
      var other = document.getElementsByName('other_choice')[0].value;
      var { xing, ming, phone, email, city, State, rl, postal, calendar, calendar2, calendar3, Date, home_address, home_address_two, calendar1 } = this.form;
      if (!calendar3) {
        this.$message.error('Date is required');
        return;
      }

      if (school !== "Other Choice") { document.getElementsByName('other_choice')[0].value = ""; }

      this.$refs.esign3.generate().then(res => {
        this.resultImg3 = res;
        HomeSubmit({
          other_choice: other,
          id: this.$route.query.id,
          host_first_name: xing, host_last_name: ming, host_phone: phone,
          host_email: email, home_address: home_address, home_address_two: home_address_two,
          city: city, continent: State, postcode: postal, host_date_first: rl,
          host_date_second: calendar1, host_date_third: calendar2, host_date_fourth: calendar3,
          signature_first: this.resultImg, signature_second: this.resultImg1,
          signature_third: this.resultImg2, signature_fourth: this.resultImg3,
          fiscal_date: Date, school_rides: school, type_of_hosting: hosting, deposit_fee: Deposit, designated_airport: airport,
        }).then(res => {
          if (res.code == 200) {
            this.$message.success('Thank you for your successful submission');
            this.form = {};
          }
        });
      });
    },
    HandleHome() {
      Operator().then(res => {
        if (res.code == 200) {
          this.Content = res.data
        }
      });
    },
  },
};

</script>

<style lang="scss" scoped>
::v-deep .other-choice {
  margin: 10px 0 0 0;
  font-weight: bold;
}

::v-deep .top-10 {
  margin: 10px auto 0 auto;
}

::v-deep .top-10 .weight-14 {
  margin: 0 0 10px 0;
}

::v-deep p {
  margin: 10px 0;
}

::v-deep p img {
  width: 100% !important;
  height: auto !important;
}

::v-deep li span {
  margin-top: 10px;
  // display: block;
}

.text {
  font-size: 18px;
  text-align: center;
  margin: 20px auto 20px auto;
  font-weight: bold;
}

.hostname {
  font-size: 14px;
  margin: 10px 0 20px 0;
  font-weight: 500;
}

.mySign {
  width: 450px !important;
  border: 1px solid #DCDFE6;
}

.admin {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.minwidth {
  width: 800px;
  margin: 0 auto;
}

::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

::v-deep .el-form-item__label {
  float: none;
  font-weight: bold;
}

::v-deep .weight-12 {
  font-size: 12px;
  font-weight: 600;
}

::v-deep .top-10 {
  margin-top: 10px;
  font-family: 微软雅黑;
  font-size: 12px;
}

::v-deep .color-red {
  color: red
}

::v-deep .top-left {
  margin: 10px 30px;
}

::v-deep .size-12 {
  font-size: 12px;
}

::v-deep .weight-360 {
  width: 360px;
}

::v-deep .other-choice {
  font-family: 微软雅黑;
  font-size: 12px;
}
</style>